@font-face {
  font-family: "IBMPlexSans";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "TTMussels";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/TTMussels/TTMussels-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "TTMussels-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/TTMussels/TTMussels-Bold.ttf")
    format("truetype");
}


html {
  font-family: 'IBMPlexSans';
  color: white;
  height: "100vh";
  width: "100vw";
}

a {
  color: orange;
}

/* ::-webkit-scrollbar {
  display: none;
} */